<header class="header-container">
  <div class="container-fluid">
    <div class="header-main d-flex justify-content-between align-items-center">
      <a class="text-muted" href="#">
        <img class="header-logo" src="assets/img/logo.png" alt="reisinger logo" />
      </a>

      <a class="header-text">Konzentrationsübung</a>
    </div>
  </div>
  <hr />


</header>