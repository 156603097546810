<div class="row">
    <div class="container">
        <div class="card justify-content-center">
            <div class="card-header d-md-flex">
                <h5 class="card-title">Dein Spiel</h5>
            </div>
            <div class="card-body">
                <div class="row d-flex justify-content-center">


                    <div class="col-md-1">
                        <div class="text-center">
                            <h3 [ngClass]="hiddenStyle"> {{ number1 }}</h3>
                        </div>

                        <div class="text-center">
                            <h3>+</h3>
                        </div>

                        <div class="text-center">
                            <h3>{{ number2 }}</h3>
                        </div>

                        <div class="text-center">
                            <h3>=</h3>
                        </div>
                        <div class="text-center">
                            <h3>?</h3>
                        </div>
                    </div>
                </div>

                <div class="container d-md-none ">
                    <div class="row my-2">

                        <button class="btn btn-secondary mx-1" (click)="onNumpad(5)">5</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(6)">6</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(7)">7</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(8)">8</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(9)">9</button>
                    </div>
                    <div class="row my-2">
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(0)">0</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(1)">1</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(2)">2</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(3)">3</button>
                        <button class="btn btn-secondary mx-1" (click)="onNumpad(4)">4</button>
                    </div>

                </div>

                <div class="row d-flex justify-content-center my-2 ">
                    <button class="btn btn-primary" (click)="onStart()">{{ buttonText }}</button>
                    <button *ngIf="isStarted" class="btn btn-warning mx-5" (click)="onNew()">Überspringen</button>
                </div>

                <br>
                <div class="alert alert-info" role="alert">
                    <strong>Info! </strong>Die Aufgabe des Spiel ist es, die Summe der beiden Zahlen zu berechnen
                    und die letzte Ziffer vom Ergebnis auf der Tastatur einzugeben. <br>
                    Nach dem Starten des Spiels, verschwindet die erste Zahl - die musst du dir merken. Wenn die Zahl
                    richtig eingegeben wird, kommt eine neue Rechnung. Dabei wird die zweite Zahl zur ersten, und die
                    zweite Zahl wird neu generiert.<br><br>

                    <strong>Hinweis! </strong>Du kannst, ohne das Spiel zu starten, schon üben. Dann siehts du wie die
                    neue Rechnung ensteht. Einfach über die Tastatur die letzte Ziffer des Ergebnisses eingeben.
                </div>
            </div>
        </div>
    </div>

</div>