import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key >= '0' && event.key <= '9') {
      this.key = event.key;
      if (this.key === this.result.toString()) {
        this.newCalc();
        if (this.isStarted) {
          this.good++;
        }
      }
      else {
        if (this.isStarted) {
          this.bad++;
        }
      }
    }
  }

  key: any;
  hiddenStyle = "";

  number1: number;
  number2: number;
  result: number;

  good: number;
  bad: number;

  isStarted: boolean;

  buttonText: string = "Spiel starten";

  maxNumber: number = 9;


  constructor() { }

  ngOnInit(): void {
    this.number2 = Math.floor(Math.random() * this.maxNumber) + 1;
    this.newCalc();
  }

  newCalc() {
    this.number1 = this.number2;
    this.number2 = Math.floor(Math.random() * this.maxNumber) + 1;

    this.result = (this.number1 + this.number2) % 10;
  }

  onStart() {
    if (this.buttonText === "Spiel starten") {
      this.isStarted = true;
      this.hiddenStyle = "my-hidden";
      this.buttonText = "Spiel stoppen";
      this.good = 0;
      this.bad = 0;
    }
    else {
      this.isStarted = false;
      this.hiddenStyle = "";
      this.buttonText = "Spiel starten";
    }
  }

  onNew() {
    this.newCalc();
  }

  onNumpad(n: number) {

    if (n == this.result) {
      this.newCalc();
      if (this.isStarted) {
        this.good++;
      }
    }
    else {
      if (this.isStarted) {
        this.bad++;
      }
    }

  }

}
