import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { take, map } from 'rxjs/Operators'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  collapsed = true;

  displayName: string = "DEMO";
  userName: string = "Unbekannt";
  isAutheticated: boolean = false;
  private userSub: Subscription;
  private appSub: Subscription;

  constructor() { }

  ngOnInit(): void {


  }

  ngOnDestroy() {

    //this.appSub.unsubscribe();
  }


}
